import React from "react";

function Footer() {
  return (
    <div className="container">
      <p className="footer">Designed & Built by Shimey Loo</p>
    </div>
  );
}

export default Footer;